import { Fade, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "swiper/css/pagination";
import CareerTitle from "../../../assets/images/LandingPage/Career/CareerTitle.png";
import AnimationController from "../../../components/AnimationController/AnimationController";
import styles from "./index.module.scss";
import { pxToRem } from "../../../helpers/setRem";



const Career = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:780px');
    const [activeSlide, setActiveSlide] = useState(0);

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 100); // Delay might be adjusted based on your needs

        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, [pathname]);

    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0,  maxWidth: '1900px', width: '100%', alignSelf: 'center'}}>
            <Stack direction="column" width={'100%'} marginTop={isMobile ? pxToRem(80) :pxToRem(80)}>
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_1}>
                                <img className={styles.home_1_img} src={CareerTitle} alt="Career at Kepple" />
                            </Stack>

                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_1}>
                                <Typography className={styles.home_ApproachTitle}>
                                    Build your career with us, and have fun doing it.
                                </Typography>
                                <Stack className={styles.home_2_founderContainer}>
                                    <Stack className={styles.home_2_founderTitleBox}>
                                        <Typography className={styles.home_2_founderTitle}>
                                            OUR TEAM
                                        </Typography>
                                    </Stack>
                                    <Stack className={styles.home_2_founderBox}>
                                        <Typography className={styles.home_2_founderText}>
                                            Our team is made up of a diverse group of professionals, including UI/UX designers, developers, project coordinators, marketers, administrators, and more.<br /><br />
                                            We're always looking for talented individuals with unique perspectives, skills, and experiences to join us and help drive our products forward. <br /><br />
                                            Open-minded, proactive, and creative thinkers are always welcome at Kepple.
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {/* <Stack className={styles.home_2_textContainer}>
                                    <Typography className={styles.home_titleBold}>
                                        OUR STORY
                                    </Typography>
                                    <Stack className={styles.home_2_textBox}>
                                        <Typography className={styles.home_2_text}>
                                            Founded in late 2021  the initial idea for Kepple was to build a mobile application that would provide the modern ride-share experience to schools sourcing casual relief teachers.<br /><br />
                                            The business plan was simple, pay teachers fairly, streamline the process for schools, and keep costs competitive by utilising technology to minimise overheads.<br /><br />
                                            Without any external investment, Kepple was able to sustainably scale the Spark Relief Teachers platform into an industry leading, profitable solution that allows our business to invest in new projects.<br /><br />
                                            The problem statement that schools needed a solution to securely store and utilise their alumni data was raised in late 2022. Six months later, Kepple committed to designing and developing Loop Alumni and within the subsequent 6 months the Beta was live. <br /><br />
                                            Loop is now an active service helping schools worldwide securely manage and store their alumni data.
                                        </Typography>
                                    </Stack>
                                </Stack> */}
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.2}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_2}>
                                <Stack className={styles.home_2_SlogenBox}>
                                    <Typography className={styles.home_3_titleBold}>
                                        Our "people first" philosophy shapes our team and our tech.
                                    </Typography>
                                </Stack>
                                <Stack className={styles.home_2_textContainer}>
                                    <Typography className={styles.home_titleBold}>
                                        LIFE AT KEPPLE
                                    </Typography>
                                    <Stack className={styles.home_2_textBox}>
                                        <Typography className={styles.home_2_text}>
                                            At Kepple, no two days are the same, with new challenges around every corner. It's a place for those who love to learn, thrive on face-to-face communication, and are eager to take on challenges.<br />
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack className={styles.home_2_boxContainer}>
                                    <Stack className={styles.home_2_about}>
                                        <Typography className={styles.home_subTitle} color={'#FFFFFF'}>
                                            People Focused
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_text}>
                                            Our people consistently go above and beyond for the business, and we believe it's a two-way street.
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtitle}>
                                            This looks like:
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Early finish Fridays to kick-start your weekend
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Enjoy your birthday off, on us
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Annual remuneration reviews
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Bi-annual promotion opportunities
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • All of the office benefits you could hope for!
                                        </Typography>

                                    </Stack>
                                    <Stack className={styles.home_2_about}>
                                        <Typography className={styles.home_subTitle} color={'#FFFFFF'}>
                                            Culture Values
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_text}>
                                            Life is bigger than work – though that doesn't stop us from trying to make it as fun and engaging as we can!                                            </Typography>
                                        <Typography className={styles.home_2_inbox_subtitle}>
                                            This looks like:
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • In-person interactions
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Diverse people in our teams
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Regular company functions
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Annual Christmas party
                                        </Typography>
                                        <Typography className={styles.home_2_inbox_subtext}>
                                            • Social events
                                        </Typography>


                                    </Stack>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.2}
                    animationCanReplay={false}
                />


                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_2}>
                                <Stack className={styles.home_2_imgContainer}>

                                </Stack>
                                <Stack className={styles.home_2_textContainer}>
                                    <Typography className={styles.home_titleBold}>
                                        The Office
                                    </Typography>
                                    <Stack className={styles.home_2_textBox}>
                                        <Typography className={styles.home_2_text}>
                                            Our office is located in the heart of Richmond in Melbourne, where all team members work.<br /><br />
                                            The in-office experience enables us to react quickly to change and creates an environment where we are well-positioned to develop both our products and our people.<br /><br />
                                            To support this, we invest significantly in making our workspace a positive and enjoyable place to work, ensuring our team can thrive in a dynamic and collaborative setting.<br />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.1}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_4} direction={'column'}>
                                <Stack className={styles.home_4_Container}>
                                    <Stack className={styles.home_4_textBox} direction={'column'}>
                                        <Typography className={styles.home_4_titleBold}>
                                            Are you aligned with what drives us?
                                        </Typography>

                                        <Typography className={styles.home_4_text}>
                                            We're always looking for great people to join our team. See what opportunities are currently available or send us a message - we'd love to hear from you!
                                        </Typography>
                                    </Stack>
                                    <button className={styles.contactButton} onClick={() => navigate('/opportunity')}>
                                        <Typography className={styles.contactButtonText}>
                                            Current Opportunities
                                        </Typography>
                                    </button>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
            </Stack>
        </div>
    );
};

export default Career;
