import { Box, Fade, Grow, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import slide1 from "../../../assets/images/LandingPage/SlidesImg/slide1.png";
import slide2 from "../../../assets/images/LandingPage/SlidesImg/slide2.png";
import slide3 from "../../../assets/images/LandingPage/SlidesImg/slide3.png";
import slide4 from "../../../assets/images/LandingPage/SlidesImg/slide4.png";
import slide5 from "../../../assets/images/LandingPage/SlidesImg/slide5.png";
import "swiper/css/pagination";

import { URL_PARAMS } from "../../../routers/shared";
import TransitionComponent from "../component/TransitionComponent";
import styles from "./index.module.scss";
import { pxToRem } from "../../../helpers/setRem";
import AnimationController from "../../../components/AnimationController/AnimationController";
import { PrimaryButton } from "../../../components/Buttons/ButtonUI";
import FloatingComponent from "../component/FloatingComponent";
import normalFloatingComponent from "../component/FloatingComponent/normalBanner";
import LocationIcon from "../../../assets/images/LandingPage/LocationIcon.png";
import TechIcon from "../../../assets/images/LandingPage/TechIcon.png";
import AgileIcon from "../../../assets/images/LandingPage/AgileIcon.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import CarouselWrapper from "../../../components/CarsouelWrapper";
import Slide from "../component/Slides/Slide";
import NormalFloatingComponent from "../component/FloatingComponent/normalBanner";


const Home = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:780px');
    const [activeSlide, setActiveSlide] = useState(0);

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, [pathname])

    const slideTexts = [
        {
            title: "1. Industry & Problem Validation",
            description: "Before starting any project, we ensure it passes Kepple's 'Pub Test'. We ask two key questions: Does this project solve a specific, tangible problem? And, does it create a positive impact for all stakeholders involved? By focusing on these criteria, we ensure our solutions are both effective and beneficial across the board."
        },
        {
            title: "2. The Ideal vs. Reality",
            description: "While the initial idea may be a tangible, attainable solution, it's important to ask whether it's truly the ideal solution. By comparing the ideal outcome with the proposed approach, we assess if the perfect solution is achievable by us or society. This comparison helps determine if the project is worth pursuing or if adjustments are needed to reach a more optimal result."
        },
        {
            title: "3. Financial Viability & Business Model",
            description: "We're a for-profit company, focused on developing and operating technology to ensure we can continuously evolve and improve our solutions. Financial viability and a solid business model are essential considerations for us. Before moving forward with any project, we rigorously assess its potential to generate profit and sustain long-term growth, ensuring that we can continue delivering value while fulfilling our commitment to innovation."
        },
        {
            title: "4. Sprint to Launch",
            description: "From this point, it's a sprint to launch the Beta. We move quickly to complete everything—from UI/UX design and development to finalising the business plan, licensing, and compliance. Our focus is getting all the elements in place as efficiently as possible to bring the project to life."
        },
        {
            title: "5. Product Iteration",
            description: "Perfection is never the end goal—there's always room for improvement. That's why we embrace continuous iteration, constantly refining our products. As an Agile business, we focus on delivering functional technology quickly, prioritising adaptability, and progress over perfection. This mindset ensures we're always evolving to meet the needs of our users."
        }

    ];

    const icons = [
        { icon: LocationIcon, title: "All in One Place", desc: "We design, build, operate and maintain our own technology" },
        { icon: TechIcon, title: "Technology with Impact", desc: "The technology we build is designed for industries that need it most" },
        { icon: AgileIcon, title: "An Agile Company", desc: "Our top priority is consistently satisfying the end user through early and continuous delivery" }
    ];

    const CARSOUEL_CONFIG = [
        {
            imageUrl: slide1,
            description: slideTexts[0].description,  // Industry & Problem Validation
            title: slideTexts[0].title,
        },
        {
            imageUrl: slide2,
            description: slideTexts[1].description,  // The Ideal vs. Reality
            title: slideTexts[1].title,
        },
        {
            imageUrl: slide3,
            description: slideTexts[2].description,  // Financial Viability & Business Model
            title: slideTexts[2].title,
        },
        {
            imageUrl: slide4,
            description: slideTexts[3].description,  // Sprint to Launch
            title: slideTexts[3].title,
        },
        {
            imageUrl: slide5,
            description: slideTexts[4].description,  // Product Iteration
            title: slideTexts[4].title,
        },
    ];
    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0,  maxWidth: '1900px', width: '100%', alignSelf: 'center'}}>
            <Stack direction="column" width={'100%'} marginTop={isMobile ? pxToRem(80) : pxToRem(80)}> {/* Adjusted pxToRem to simple string for simplification */}
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_1}>
                                <Stack className={styles.home_1_intro}>
                                    <Typography className={styles.home_title}>
                                        Say hello to <strong>Kepple</strong>,
                                    </Typography>
                                    <Typography className={styles.home_text}>
                                        A Melbourne-based tech startup dedicated to developing digital products and experiences that create meaningful impact. Our entirely in-house, people-driven team is committed to cultivating creativity and innovation while addressing real-world challenges.
                                    </Typography>
                                </Stack>
                                <Stack className={styles.home_1_boxContainer}>
                                    <Stack className={styles.home_1_topContainer}>
                                        <Stack className={styles.home_1_about} onClick={()=>navigate('/about')}>
                                            <Typography className={styles.home_subTitle} color={'#FFFFFF'}>
                                                About Us
                                            </Typography>
                                        </Stack>
                                        <Stack className={styles.home_1_product} onClick={()=>navigate('/product')}>
                                            <Typography className={styles.home_subTitle} color={'#FFFFFF'}>
                                                Our Products
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack className={styles.home_1_career} onClick={()=>navigate('/career')}>
                                        <Typography className={styles.home_subTitle} color={'#333333'}>
                                            Careers
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_2}>
                                <Stack className={styles.home_2_imgContainer}>
                                    {!isMobile && (
                                        <>
                                            <FloatingComponent
                                                imagePath={LocationIcon}
                                                title="All in one place"
                                                description="We design, build, operate, and maintain our own technology in-house."
                                                top="25"
                                                left="30"
                                            />
                                            <FloatingComponent
                                                imagePath={TechIcon}
                                                title="Technology with impact"
                                                description="The technology we build is designed for industries that need it most."
                                                top="50"
                                                left="24"
                                            />
                                            <FloatingComponent
                                                imagePath={AgileIcon}
                                                title="An Agile company"
                                                description="Our top priority is consistently satisfying the end user through early and continuous delivery."
                                                top="75"
                                                left="30"
                                            />
                                        </>
                                    )}
                                </Stack>
                                {isMobile && <Box className={styles.diagonalContainer}>
                                    {icons.map((item, index) => (
                                        <Box
                                            key={index}
                                            className={styles.diagonalItem}

                                        >
                                            <NormalFloatingComponent
                                                imagePath={item.icon}
                                                title={item.title}
                                                description={item.desc}
                                            />
                                        </Box>
                                    ))}
                                </Box>}
                                <Stack className={styles.home_2_textContainer}>
                                    <Typography className={styles.home_titleBold}>
                                        Our Vision
                                    </Typography>
                                    <Stack className={styles.home_2_textBox}>
                                        <Typography className={styles.home_2_text}>
                                            Kepple's vision is to accelerate the adoption of modern technology to support industries to embrace and benefit from innovation.
                                            <br /><br />
                                            By developing cutting-edge mobile and web-based applications, we empower businesses to improve efficiency, sustainability, and growth.
                                            <br /><br />
                                            Our goal is to bridge the gap between technology and these sectors, driving progress that benefits businesses and society alike.
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.1}
                    animationCanReplay={false}
                />
                <Typography className={styles.home_ApproachTitle}>
                    Our Approach
                </Typography>
                <Stack className={styles.homeSwiper}>
                    <CarouselWrapper>
                        {CARSOUEL_CONFIG.map((config, index) => (
                            <div key={`carsouel-slide-${index}`}>
                                <AnimationController
                                    render={(loadedAnimation: boolean) => (
                                        <Slide
                                            backgroundImageUrl={config.imageUrl}
                                            title={config.title}
                                            description={config.description}
                                            loadedAnimation={loadedAnimation}
                                        />
                                    )}
                                    animationCanReplay
                                />
                            </div>
                        ))}
                    </CarouselWrapper>
                </Stack>
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_3}>
                                <Stack className={styles.home_3_Container}>
                                    <Stack className={styles.home_3_textBox}>
                                        <Typography className={styles.home_3_titleBold}>
                                            Got a big idea?
                                        </Typography>
                                        <Typography className={styles.home_3_text} color={'#fff'}>
                                            We're excited to explore new ideas and collaborate with motivated entrepreneurs. If you have an idea to share, click here to learn more about how we can work together.
                                        </Typography>
                                    </Stack>
                                    <button className={styles.ideaButton} onClick={() => navigate('/pitch')}>
                                        <Typography className={styles.ideaButtonText}>
                                            Pitch your idea here
                                        </Typography>
                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.1514 13.6543C20.1426 13.3467 20.0283 13.083 19.791 12.8457L12.9531 6.15723C12.751 5.96387 12.5137 5.8584 12.2236 5.8584C11.6348 5.8584 11.1777 6.31543 11.1777 6.9043C11.1777 7.18555 11.292 7.44922 11.4941 7.65137L17.6465 13.6543L11.4941 19.6572C11.292 19.8594 11.1777 20.1143 11.1777 20.4043C11.1777 20.9932 11.6348 21.4502 12.2236 21.4502C12.5049 21.4502 12.751 21.3447 12.9531 21.1514L19.791 14.4541C20.0371 14.2256 20.1514 13.9619 20.1514 13.6543Z" fill="white" />
                                        </svg>
                                    </button>


                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_4} direction={'column'}>
                                <Stack className={styles.home_4_Container}>
                                    <Stack className={styles.home_4_textBox} direction={'column'}>
                                        <Typography className={styles.home_4_titleBold}>
                                            Let's talk
                                        </Typography>
                                        <Typography className={styles.home_4_text} color={'#fff'}>
                                            We'd love to hear from you. Share your ideas or pain points – we're here to talk all things tech and answer any questions you have about us.                                        </Typography>
                                    </Stack>
                                    <button className={styles.contactButton} onClick={() => navigate('/contact')}>
                                        <Typography className={styles.contactButtonText}>
                                            Contact Us
                                        </Typography>
                                    </button>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
            </Stack>
        </div>
    );
};

export default Home;
