import { DialogContent, Fade, Grid, IconButton, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { getContactUsError, getErrorMsgForContactNumber, getErrorMsgForEmailInput, getErrorMsgForInput } from "../../../helpers/inputValidators";
import useTextInput from "../../../hooks/useTextInput";
import styles from "./Contact.module.scss";
import { useLocation } from "react-router-dom";
import { pxToRem } from "../../../helpers/setRem";
import { WhiteMultilineTextField, WhiteTextField } from "../../../components/TextField";
import UserAlert from "../../../components/AlertComponent/BasicAlert";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { uploadAllTypeFileToStorage, uploadFileToStorageWithProgress } from "../../../firebase/storageApis";
import { LoadingButton } from "@mui/lab";

// Helper function to format file size
export function formatFileSize(size: number): string {
    if (size < 1024) return size + ' bytes';
    let sizeInKB = size / 1024;
    if (sizeInKB < 1024) return sizeInKB.toFixed(2) + ' KB';
    let sizeInMB = sizeInKB / 1024;
    return sizeInMB.toFixed(2) + ' MB';
}


const ContactPage = () => {
    const isMobile = useMediaQuery('(max-width:440px');
    const name = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "First Name", true); } });
    const number = useTextInput({ inputValidator: (input) => { return getErrorMsgForContactNumber(input, true); } });
    const email = useTextInput({ inputValidator: (input) => { return getErrorMsgForEmailInput(input, true) } });
    const message = useTextInput({ inputValidator: (input) => { return getContactUsError(input, 2, "Message", false); } });
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

    const [attachText, setAttachText] = useState("Attach File")
    const [file, setFile] = useState<File | null>(null); // Assuming you have a state to keep the file
    const [fileName, setFileName] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const isUnderline = attachText !== "Attach File";

    // Combine classes conditionally
    const paragraphClassName = `${styles.paragraphClass} ${isUnderline ? styles.paragraphClassUnderline : ''}`;


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            const allowedTypes = [
                'application/pdf',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'image/jpeg',
                'image/png',
                'text/csv'
            ];

            if (!allowedTypes.includes(file.type) || file.size > 10485760) { // 10MB
                setAlertOpen(true);
                return;
            }

            setFile(file);
            setFileName(file.name);

            // Format file size for display
            const fileSize = formatFileSize(file.size);

            // Set attach text with filename and size
            setAttachText(`${file.name.length > 25 ? `${file.name.substring(0, 25)}...` : file.name} (${fileSize})`);
        }
    }


    const resetForm = () => {
        name.setValue(""); name.setHasTouched(false);
        email.setValue(""); email.setHasTouched(false);
        number.setValue(""); number.setHasTouched(false);
        message.setValue(""); message.setHasTouched(false);
        setFile(null);
        setFileName("");
        setAttachText("Attach File");

    }

    const emailServerUrl = "https://public.herotofu.com/v1/ab7c67e0-92e0-11ed-a003-6f0b76086b1c";
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const textFieldProps = {
        sx: {
            borderRadius: '10px',
            '& .MuiOutlinedInput-root':
            {
                'fieldset': { border: `1px solid ${styles.primary400}`, },
                '&.Mui-focused fieldset': { border: `2px solid ${styles.primary500}` },
                '&:hover fieldset': { border: `2px solid ${styles.primary400}`, }
            }
        },
        inputProps: {
            style: {
                color: styles.shades100
            },
        },
        InputLabelProps: {
            style: {
                color: styles.primary400
            }
        }
    }

    const multilineProps = {
        sx: {
            borderRadius: '10px',
            '& .MuiOutlinedInput-root':
            {
                padding: 0,
                'fieldset': { border: `1px solid ${styles.primary400}`, },
                '&.Mui-focused fieldset': { border: `2px solid ${styles.primary500}` },
                '&:hover fieldset': { border: `2px solid ${styles.primary400}`, }
            }
        },
        inputProps: {
            style: {
                color: styles.shades100
            },
            maxLength: 500
        },
        InputLabelProps: {
            style: {
                color: styles.primary400
            }
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Start by ensuring the form has been touched and validated
        const inputs = [name, email, number, message];
        inputs.forEach(input => input.setHasTouched(true));
        const isFormValid = inputs.every(input => !input.hasError);

        if (!isFormValid) {
            console.error("Form validation failed.");
            return;
        }

        setLoading(true);


        let fileUrl = '';
        if (file) {
            const filePath = `0000000000000-PUBLIC/keppleweb/contact/${file.name}`;
            try {
                const uploadResult = await uploadAllTypeFileToStorage({ file, path: filePath });
                if (uploadResult.code !== 200) {
                    throw new Error("Failed to upload file.");
                }
                fileUrl = uploadResult.data; // URL of the uploaded file
            } catch (error) {
                console.error("Error uploading file:", error);
                setErrorMsg("Failed to upload file. Please try again.");
                setAlertOpen(true);
                setLoading(false);
                return;
            }
        }

        // Prepare data to be sent to your email server
        // const data = {
        //     email: email.value,
        //     name: name.value,
        //     message: message.value + fileUrl + number.value,
        // };
        const formattedMessage = `
            CONTACT NUMBER: ${number.value}\n
            MESSAGE: ${message.value}\n
            FILE URL: ${fileUrl}\n`;

        // Send the data to the server
        fetch(emailServerUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.value,
                name: name.value,
                message: formattedMessage,
            }),
        }).then(response => {
            if (response.status === 200) {
                setOpenAlert(true);
                resetForm();
            } else {
                throw new Error("Failed to send email.");
            }
        }).catch(error => {
            console.error("Failed to send request:", error);
            alert('Failed to send request. Please try again.')
        }).finally(() => {
            setLoading(false);
        });
    };

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, [pathname])

    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0, width: '100%', alignSelf: 'center' }}>
            <Stack direction="column" width={'100%'} marginTop={isMobile ? pxToRem(80) :pxToRem(80)}>
                <UserAlert
                    open={openAlert}
                    handleClose={() => setOpenAlert(false)}
                    title="Thank you for reaching out!" // No title in your example
                    content="We have received your query and will endeavour to respond to you within 3 business days. We appreciate your patience as our team processes your request."
                    closeButtonText="Close"
                    closeOutlined={true}
                />

                <UserAlert
                    open={alertOpen}
                    handleClose={() => setAlertOpen(false)}
                    title="Uploading File" // No title in your example
                    content="Unfortunately, the file you are attempting to upload exceeds our size limit of 10MB. Please select a smaller file and try again. We appreciate your understanding. "
                    closeButtonText="Close"
                    closeOutlined={true}
                />
                {/* Other component content */}
                <Stack className={styles.contact1}>
                    <h2>Contact Us</h2>
                    <p>We'd love to hear from you. Share your ideas or pain points - we're here to talk all things tech and answer any questions you have about us and what we do.</p>
                </Stack>

                <Stack className={styles.contact2}>
                    <Fade in={true} timeout={1000}>
                        <Stack className={styles.formContainer}>
                            <form
                                action={emailServerUrl}
                                onSubmit={handleSubmit}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            variant="filled"
                                            value={name.value}
                                            onChange={(e) => name.setValue(e.target.value)}
                                            label='Full Name'
                                            name="fullName"
                                            error={name.hasError && name.hasTouched}
                                            helperText={<small>{name.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={email.value}
                                            variant="filled"
                                            onChange={(e) => email.setValue(e.target.value)}
                                            label='Email Address'
                                            name="email"
                                            type="email"
                                            error={email.hasError && email.hasTouched}
                                            helperText={<small>{email.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={number.value}
                                            variant="filled"
                                            onChange={(e) => number.setValue(e.target.value)}
                                            label='Contact Number'
                                            name="number"
                                            error={number.hasError && number.hasTouched}
                                            helperText={<small>{number.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <WhiteMultilineTextField
                                            fullWidth
                                            value={message.value}
                                            onChange={(e) => message.setValue(e.target.value)}
                                            label='Message'
                                            name="message"
                                            multiline
                                            rows={2}
                                            required
                                            {...multilineProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction={'row'} style={{ alignItems: 'start' }}>
                                            <input
                                                accept=".pdf,.csv,.xls,.xlsx,.doc,.docx,.jpg,.png"
                                                style={{ display: 'none' }}
                                                id="raised-button-file"
                                                multiple
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                            <label htmlFor="raised-button-file" style={{ cursor: 'pointer' }}>
                                                <Stack direction={"row"}>
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.1525 9.89952L10.1369 18.9151C8.08662 20.9654 4.7625 20.9654 2.71225 18.9151C0.661997 16.8649 0.661998 13.5408 2.71225 11.4905L11.7279 2.47489C13.0947 1.10806 15.3108 1.10806 16.6776 2.47489C18.0444 3.84173 18.0444 6.05781 16.6776 7.42464L8.01555 16.0867C7.33213 16.7701 6.22409 16.7701 5.54068 16.0867C4.85726 15.4033 4.85726 14.2952 5.54068 13.6118L13.1421 6.01043" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <p className={paragraphClassName}>
                                                        {attachText}
                                                    </p>

                                                </Stack>
                                            </label>
                                            {file && (
                                                <Stack onClick={() => { setFile(null); setFileName(''); setAttachText('Attach File') }}>
                                                    <DeleteOutlinedIcon sx={{ color: 'white', cursor: 'pointer' }} />
                                                </Stack>

                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Stack alignItems={'center'}>
                                            <LoadingButton
                                                loading={loading}
                                                type="submit"
                                                disabled={
                                                    name.hasError || email.hasError || number.hasError || message.hasError ||
                                                    name.value === '' ||
                                                    email.value === '' ||
                                                    number.value === '' ||
                                                    message.value === ''
                                                }
                                                className={styles.sendButton}
                                            >
                                                {loading ? '' : 'SUBMIT REQUEST'}
                                            </LoadingButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </Stack>
                    </Fade>
                </Stack>
            </Stack>
        </div>
    )
}


export default ContactPage;
