
import { lazy } from "react";
import { Route } from "react-router-dom";
import Home from "../pages/LandingPage/Home";
import About from "../pages/LandingPage/About";
import Product from "../pages/LandingPage/Product";
import Career from "../pages/LandingPage/Career";
import Opportunities from "../pages/HomePage/components/Opportunities";
import Opportunity from "../pages/LandingPage/Opportunity";
import Internship from "../pages/LandingPage/Internship";
import PitchPage from "../pages/LandingPage/Pitch";
import ApplyPage from "../pages/LandingPage/Apply";
import ContactPage from "../pages/LandingPage/Contact";

// const HomePage = lazy(() =>
//   import("../pages/HomePage").then((module) => ({
//     default: module.default,
//   }))
// );

const LandingPage = lazy(() =>
  import("../pages/LandingPage").then((module) => ({
    default: module.default,
  }))
);

const SparkAdminPage = lazy(() =>
  import("../spark-admin/App").then((module) => ({
    default: module.default,
  }))
);

export const URL_PARAMS = {
  SPARK_ADMIN: "spark-admin",
};

export const ROOT_ROUTERS = {
  // HomePage: <Route key='HomePage' path={`/oldHome`} element={<HomePage />} />,
  Home: <Route key='Home' path={`/`} element={<LandingPage ><Home /></LandingPage>} />,
  About: <Route key='AboutUs' path={`/about`} element={<LandingPage ><About /></LandingPage>} />,
  Product: <Route key='Our Products' path={`/product`} element={<LandingPage ><Product /></LandingPage>} />,
  Career: <Route key='Career' path={`/career`} element={<LandingPage ><Career /></LandingPage>} />,
  Opportunity: <Route key='Opportunity' path={`/opportunity`} element={<LandingPage ><Opportunity /></LandingPage>} />,
  Internship: <Route key='Internship' path={`/internship`} element={<LandingPage ><Internship /></LandingPage>} />,
  Pitch: <Route key='Pitch' path={`/pitch`} element={<LandingPage ><PitchPage /></LandingPage>} />,
  ContactUs: <Route key='Contact Us' path={`/contact`} element={<LandingPage ><ContactPage /></LandingPage>} />,
  Apply: <Route key='Apply' path={`/apply`} element={<LandingPage ><ApplyPage /></LandingPage>} />,


  SparkAdminPage: (
    <Route
      key='SparkAdminPage'
      path={`/${URL_PARAMS.SPARK_ADMIN}/*`}
      element={<SparkAdminPage />}
    />
  ),
};
